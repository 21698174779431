<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.name"
          label="Display Name (e.g. Employee Only)"
          class="mt-5"
        />

        <translatable-input
          v-model="form.description"
          label="Description (e.g. Only employee can...)"
          class="mt-5"
        />

        <v-text-field
          v-model="form.key"
          label="Unique Key (e.g. pwc-employee-only)"
          outlined
          :rules="[required]"
        ></v-text-field>

        <v-autocomplete
          v-model="form.channel_id"
          label="Destinated Channel"
          :items="channelOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
          :rules="[between(form.channel_id, 1, 999999999)]"
        ></v-autocomplete>

        <div class="demo-space-x">
          <v-switch
            v-model="form.active"
            color="primary"
            :label="form.active ? 'Active' : 'Inactive'"
            :disabled="disabledFields.indexOf('active') >= 0"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useAlphaDash, useSlugify, useTranslatable } from '@/composables'
import channelStore from '@/modules/channel/store'
import { t } from '@/plugins/i18n'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: useTranslatable(props.resource, 'name'),
      description: useTranslatable(props.resource, 'description'),
      active: props.resource ? props.resource.active : false,
      key: props.resource ? props.resource.key : '',
      channel_id: props.resource ? props.resource.channel_id : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      form.value.key = useAlphaDash(useSlugify(form.value.key))
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const channelOptions = computed(() => channelStore.state.channels.map(ch => ({ title: t(ch.name), value: ch.id })))

    return {
      form,
      formElem,

      validate,
      between,
      required,

      channelOptions,
    }
  },
}
</script>
