<template>
  <div id="user-list">
    <!-- app drawer -->
    <note-list-add-new
      v-if="formReady"
      v-model="isAddNewNoteSidebarActive"
      :resource="resource"
      @changed="loadNotes(); isAddNewNoteSidebarActive = false"
    ></note-list-add-new>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <div class="d-flex justify-end flex-wrap">
            <template
              v-if="selectedRows.length"
            >
              <v-btn
                color="success"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllNotes({ active: 1 })"
              >
                <span>Activate</span>
              </v-btn>
              <v-btn
                color="warning"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllNotes({ active: 0 })"
              >
                <span>Deactive</span>
              </v-btn>
            </template>
            <v-btn
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="addNewResoure"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Note</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="noteListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- ID -->
        <template #[`item.id`]="{item}">
          <span>{{ item.id }}</span>
        </template>

        <!-- Key -->
        <template #[`item.key`]="{item}">
          <span
            class="cursor-pointer"
            @click="setNote(item)"
          >
            {{ item.key }}
          </span>
        </template>

        <!-- Note -->
        <template #[`item.note`]="{item}">
          <span
            class="cursor-pointer"
            @click="setNote(item)"
          >
            {{ t(item.name) }} - {{ t(item.description) }}
          </span>
        </template>

        <!-- Channel -->
        <template #[`item.channel`]="{item}">
          {{ item.channel.code }}
        </template>

        <!-- Active -->
        <template #[`item.status`]="{item}">
          <v-chip :color="item.active ? 'success' : ''">
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setNote(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deleteNote(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { destroyNote } from '@/api/channel'
import { t } from '@/plugins/i18n'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPencil,
mdiPlus,
mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { useNotifyErrors } from '@/composables'
import { avatarText } from '@core/utils/filter'
import NoteListAddNew from '../note-resource/NoteAside.vue'

import useNoteList from './useNoteList'

export default {
  components: {
    NoteListAddNew,
  },
  setup() {
    const {
      noteListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadNotes,
      updateAllNotes,
    } = useNoteList()

    const isAddNewNoteSidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const deleteNote = note => destroyNote(note.id).then(loadNotes).catch(useNotifyErrors)

    const setNote = note => {
      resource.value = note
      isAddNewNoteSidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      isAddNewNoteSidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      noteListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      isAddNewNoteSidebarActive,
      formReady,
      resource,
      selectedRows,
      addNewResoure,

      avatarText,
      loadNotes,
      deleteNote,
      setNote,
      updateAllNotes,

      t,

      // icons
      icons: {
        mdiPencil,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
